import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  margin: auto;
  padding-bottom: 35px;
  padding-top: 10px;
  width: 80%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    max-width: 600px;
    padding-top: 50px;
    padding-bottom: 100px;
  }
`;
