import React from "react";
import Layout from "../components/layout";
import DeliveryNotAvailable from "../components/delivery-not-available";

const DeliveryNotAvailablePage = (): JSX.Element => (
  <Layout header>
    <DeliveryNotAvailable />
  </Layout>
);

export default DeliveryNotAvailablePage;
