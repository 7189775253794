import AddressAutocomplete from "../address-autocomplete";
import ConfirmAddressModal from "../confirm-address-modal";
import {
  ExtendedGoogleMapsPlace,
  PARIS_COORD,
} from "../../store/address/types";
import { FormattedMessage } from "react-intl";
import { navigate } from "gatsby";
import React, { useState, useCallback } from "react";
import { Wrapper } from "./style";

const DEFAULT_ADDRESS = {
  originalPlace: undefined,
  coordinates: PARIS_COORD,
};

const DeliveryNotAvailable = (): JSX.Element => {
  const [address, setAddress] = useState<ExtendedGoogleMapsPlace>(
    DEFAULT_ADDRESS
  );
  const [confirmAddressModalIsOpen, openAddressModal] = useState<boolean>(
    false
  );

  const onPlaceChange = useCallback((newPlace: ExtendedGoogleMapsPlace): void => {
    setAddress(newPlace);
    openAddressModal(true);
  }, [setAddress, openAddressModal]);

  const addressConfirmation = useCallback((isAvailable: boolean): void => {
    if (isAvailable) {
      navigate("/cart");
    } else {
      setAddress(DEFAULT_ADDRESS);
      openAddressModal(false);
    }
  }, [setAddress, openAddressModal, navigate]);

  const closeModal = useCallback((): void => {
    openAddressModal(false)
  }, [openAddressModal]);

  return (
    <Wrapper>
      <ConfirmAddressModal
        open={confirmAddressModalIsOpen}
        closeModal={closeModal}
        initialPlace={address}
        onConfirmation={addressConfirmation}
      />
      <h3>
        <FormattedMessage id="confirmAddressModal.title" />
      </h3>
      <p>
        <FormattedMessage id="confirmAddressModal.text1" />
      </p>
      <p>
        <FormattedMessage id="confirmAddressModal.text2" />
      </p>
      <AddressAutocomplete
        inputId="address-autocomplete"
        onPlaceChange={onPlaceChange}
        placeholder={"162 bis rue Ordener, 75018 Paris, France"}
        border
      />
    </Wrapper>
  );
};

export default DeliveryNotAvailable;
